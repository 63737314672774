import React from 'react'
import PropTypes from 'prop-types'

import ICONS from 'constants/icons'

import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import CardContainer from 'components/CardContainer'

const AdciDiverCard = ({ certificate }) => {
    const {
        photo,
        cardType,
        firstName,
        lastName,
        certificateIssuer,
        issueDate,
        expireDate,
        certNumber,
    } = certificate
    return (
        <CardContainer>
            <CardHeader
                avatar={photo}
                title={`${firstName} ${lastName}`}
                placeholderIcon={ICONS.USER}
                onlyHeader={true}
                baseUrl={''}
            />
            <Info label="form.label.divingProfessional" value={cardType} />
            <Info
                label="form.label.certificateIssuer"
                iconName={
                    ICONS[certificateIssuer?.toUpperCase()] ?? ICONS.CLOSE
                }
            />
            {(issueDate || expireDate) && (
                <InfoRow>
                    <Info
                        label="form.label.issueDate"
                        value={issueDate || 'N/A'}
                    />
                    <Info
                        label="form.label.expireDate"
                        value={expireDate || 'N/A'}
                    />
                </InfoRow>
            )}
            <Info label="form.label.certNumber" value={`${certNumber}`} />
        </CardContainer>
    )
}

AdciDiverCard.propTypes = {
    certificate: PropTypes.object,
}

export default AdciDiverCard
