const changeUserArray = (item, users, setUsers, divers) => {
    const index = users?.findIndex((user) => (user.favoriteId ?? user.id) === (item.favoriteId ?? item.id))
    const usersCopy = [...(divers ? divers : users)]
    usersCopy[index] = {
        ...usersCopy[index],
        favoriteUser: !usersCopy[index].favoriteUser,
    }
    setUsers(usersCopy)
}

export { changeUserArray }
