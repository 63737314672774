const ENTITIES = {
    USER: 'users',
    DIVERS: 'divers',
    DIVERS_ELASTIC: 'divers-search',
    DIVER_POSITION: 'diver_positions',
    COUNTRY: 'countries',
    LANGUAGE: 'languages',
    INDUSTRY: 'industries',
    SERVICE: 'services',
    COMPANY_LOCATIONS: 'company_locations',
    OFFICE_TYPE: 'office_types',
    LANGUAGE_PROFICIENCY: 'language_proficiencies',
    FILE: 'files',
    ROLE: 'roles',
    JOBS_ELASTIC: 'job_posts',
    COMPANY: 'companies',
    COMPANY_ELASTIC: 'companies/search',
    MEDICAL_RECORD_TYPE: 'medical_record_types',
    MEDICAL_EXAM_TYPE: 'medical_exams',
    DIVING_MEDICAL_EXAM_STANDARD: 'medical_exam_standards',
    MEDICAL_RECORD: 'medical_records',
    GALLERY_IMAGES: 'gallery_images',
    REGION: 'regions',
    LOCATION_TYPE: 'location_types',
    PROJECT_TYPE: 'project_types',
    PROJECT_STATUS: 'project_statuses',
    DIVING_MODE: 'diving_modes',
    SKILL_CATEGORY: 'skill_categories',
    TRAVEL_DOCUMENTATION: 'travel_documentations',
    TRAVEL_DOCUMENTATION_TYPE: 'travel_documentation_types',
    VISA: 'visas',
    SEAMAN_BOOK: 'seaman_books',
    SKILL: 'skills',
    PROJECT: 'projects',
    HISTORICAL_PROJECT: 'historical_projects',
    PROJECT_ELASTIC: 'project/search',
    PAST_EMPLOYMENT: 'past_employments',
    PAST_EMPLOYMENT_FILE: 'past_employment_files',
    EMPLOYER: 'employers',
    PERSONAL_EQUIPMENT: 'personal_equipments',
    PERSONAL_EQUIPMENT_FILE: 'personal_equipment_files',
    EQUIPMENT_TYPE: 'equipment_types',
    EQUIPMENT_BRAND: 'equipment_brands',
    EQUIPMENT_MODEL: 'equipment_models',
    SKILL_RECORD: 'skill_records',
    SKILL_RECORD_BULK_SAVE: 'skill_records/bulk_save',
    EXPERIENCE: 'experiences',
    INVITE: 'diver_invites',
    DIVER_INVITE_FEEDBACK: 'diver_invite_feedbacks',
    DIVER_INVITE_COMMENT: 'diver_invite_comments',
    PROJECT_POSITIONS: 'project_positions',
    RECORD_INFORMATION: 'record_informations',
    TRAINING_COURSE: 'training_courses',
    REJECTION_TEMPLATE: 'rejection_templates',
    POSITION_REJECTION: 'position_rejections',
    TRAINING_CERTIFICATE: 'training_certificates',
    FORMAL_EDUCATION: 'formal_educations',
    PRIMARY_TRAINING_TYPE: 'primary_training_types',
    PRIMARY_TRAINING_TYPE_CATEGORY: 'primary_training_type_categories',
    TRAINING_TYPE: 'training_types',
    DIVE_SCHOOL: 'dive_schools',
    BRANCH: 'branches',
    PROFICIENCY_LEVEL: 'proficiency_levels',
    DIVER_PROJECT_POSITIONS: 'diver_project_positions',
    PROJECT_WORKER: 'project_workers',
    PROJECT_WORKER_ELASTIC: 'project_workers/search',
    BODY_OF_WATER: 'body_of_waters',
    DIVING_PLATFORM: 'diving_platforms',
    WATER_QUALITY: 'water_qualities',
    BOTTOM_CONDITION: 'bottom_conditions',
    GENERAL_INFORMATION_RECORD: 'dive_record_general_informations',
    DIVE_RECORD: 'dive_records',
    DIVE_RECORD_ELASTIC: 'dive_records/search',
    USER_SETTING: 'user_settings',
    CREATE_BASIC_ENTITY: 'basic_entity_create',
    DIVE_PHYSICAL_CHARACTERISTIC: 'dive_physical_characteristics',
    PROJECT_SUPERVISOR: 'project_supervisors',
    DIVE_RECORD_WORK_PERFORMED: 'dive_record_work_performeds',
    DIVE_RECORD_WORK_TASK: 'dive_record_work_tasks',
    DIVE_RECORD_SUPERVISOR_TASK: 'dive_record_supervisor_tasks',
    DIVE_RECORD_WORK_TOOL: 'dive_record_work_tools',
    DIVE_RECORD_WORK_OBJECT: 'dive_record_work_objects',
    DIVE_RECORD_WORK_ACTIVITY: 'dive_record_work_activities',
    MENTAL_WELLNESS_ELEMENTS: 'mental_wellness_elements',
    TRAUMA_INJURY_LEVEL: 'trauma_injury_levels',
    DECOMPRESSION_ILLNESS: 'decompression_illnesses',
    PRESSURE_INJURY_TYPE: 'pressure_injury_types',
    EQUIPMENT_FAILURE_TYPE: 'equipment_failure_types',
    CLOSE_CALL_TYPE: 'close_call_types',
    DIVE_RECORD_DIVE_SUMMARY: 'dive_record_dive_summaries',
    DIVE_RECORD_VERIFICATION: 'dive_record_verifications',
    BREATHING_APPARATUS_TYPE: 'breathing_apparatus_types',
    DIVE_RECORD_EQUIPMENT: 'dive_record_equipments',
    DIVING_APPAREL_PROTECTION: 'diving_apparel_protections',
    OTHER_DIVING_APPAREL_PROTECTION: 'other_diving_apparel_protections',
    BUOYANCY_FLOTATION: 'buoyancy_flotations',
    OTHER_EQUIPMENT_OUTFITTING: 'other_equipment_outfittings',
    GAS_CYLINDER_PURPOSE: 'gas_cylinder_purposes',
    GAS_CONTENTS: 'gas_contents',
    DIVE_EVENT: 'dive_events',
    SURFACE_DECOMPRESSION_EVENT: 'surface_decompression_events',
    CHAMBER_PRESSURIZATION_EVENT: 'chamber_pressurization_events',
    CHAMBER_DECOMPRESSION_EVENT: 'chamber_decompression_events',
    BELL_RUN_EVENT: 'bell_run_events',
    LOCK_OUT_EVENT: 'lock_out_events',
    DECOMPRESSION_TYPE: 'decompression_types',
    DECOMPRESSION_TABLE_TYPE: 'decompression_table_types',
    GAS_CYLINDER: 'gas_cylinders',
    DIVE_RECORD_DIVE_DATA: 'dive_record_dive_datas',
    COMPANY_METRICS: 'company_metrics',
    FAVORITE_USER: 'favorite_users',
    FAVORITE_USER_ELASTIC: 'favorite_users/search',
    DIVER_PROFILE: 'user_profiles',
    VESSELS: 'vessels',
    CLIENTS: 'clients',
    COMPANY_SIZE: 'company_sizes',
    USER_STATUSES: 'user_statuses',
    REGIONS: 'regions',
    SATURATION_EVENT: 'saturation_daily_events',
    PROJECT_DIVERS: 'project_divers',
    BELL_RUN_POSITIONS: 'bell_run_dive_positions',
    PROJECT_INDUSTRY_TYPES: 'project_industry_types',
    NOTIFICATIONS: 'notifications',
    MARK_READ: 'mark-all-notifications-as-read',
    MAIN_SEARCH_ITEMS: 'main_search_items',
    MOST_POPULAR_ORGANISATIONS: 'most_popular_organisations',
    CLIENT_ORGANIZATIONS: 'client_organizations',
    DIVER_PROJECTS: 'diver_projects',
    DATA_ACCESS_REQUEST: 'data_access_requests',
    COPY_DIVE_RECORD: 'copy_dive_record',
    PAST_EMPLOYMENT_POSITION: 'past_employment_positions',
    POPULAR_JOBS: 'popular_jobs',
    DIVER_DIVE_PROJECTS: 'diver_dive_projects',
    DIVE_RECORD_VERIFICATION_TOKEN:
        'dive_records/first_level_verification_token',
    DIVE_RECORD_FIRST_LEVEL_VERIFICATION:
        'dive_record_first_level_verification',
    DIVE_RECORD_FIRST_VERIFICATION_UPLOAD: 'dive_record_first_verification',
    FIRST_LEVEL_VERIFICATION: 'first_level_verification',
    FILES_GET: 'files/get/',
    COMPANY_BY_HASH: 'company-by-hash',
    MY_COMPANY: 'my-company',
    DIVER_PROFILE_BY_HASH: 'user_profile_by_hash',
    IMAGE: 'images',
    USER_BADGES: 'user_badges',
    DIVER_INVITE_STATUS: 'diver_invite_statuses',
    CV_CREATE: 'cv/create',
    VOLUME_TYPE: 'volume_types',
    DATE_FORMAT: 'date_formats',
    NOTIFICATION_SETTINGS: 'notification_settings',
    CHANGE_EMAIL: 'change_email',
    CONFIRM_EMAIL_CHANGE: 'confirm_email_change',
    RESEND_VERIFICATION_CODE: 'resend_verification_code',
    DECLINE_EMAIL_CHANGE: 'decline_email_change',
    CHANGE_PHONE_NUMBER: 'change_phone_number',
    CHANGE_PASSWORD: 'change_password',
    DATA_ACCESS_CHECK: 'data_access_check',
    DIVE_RECORD_SECOND_LEVEL_VERIFICATION:
        'dive_record_second_level_verification_requests',
    VERIFIED_DIVERS: 'verified_divers',
    ORGANIZATION_TYPES: 'organization_types',
    LOCATIONS_OF_SERVICES: 'location_of_services',
    EMPLOYED_PROJECT_POSITIONS: 'employed_project_positions',
    INVITE_USER: 'diver_invite/bulk',
    DATA_ACCESS_RESEND: 'data_access_resend',
    DELETE_ACCOUNT: '/users/delete_my_account',
    CERTIFICATES: 'certificates/check',
    CERTIFICATES_IMAGE: '/certificates/check-image',
    MEDICAL_INFO_TERMS_AND_CONDITIONS: 'medical_info_terms_and_conditions',
}

export default ENTITIES
