import { useTranslate } from 'react-polyglot'
import { useContext } from 'react'
import PropTypes from 'prop-types'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useQueryParams from 'hooks/useQueryParams'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'

import HistoricalProjectList from 'components/projects/HistoricalProjectList'
import ProjectList from 'components/projects/ProjectList'
import TabNavigation from 'components/tabs/TabNavigation'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import { getEntityFilters } from '../../services/localStorage.service'
import FILTERS from '../../constants/filters'

const PROJECT_TYPES = {
    REGULAR: 'regular',
    HISTORICAL: 'historical',
}

const ProjectListWrapper = (props) => {
    const { tab, subTab } = useQueryParams()
    const t = useTranslate()

    const { userId, isVerified } = useContext(CurrentUserContext)
    const { myProfile, profilePage, userID, user } = props

    const { meta, fetchData } = useFetchDataByQueryParams(
        ENTITIES.HISTORICAL_PROJECT,
        {
            creator_id: !myProfile && profilePage ? userID : userId,
        },
        true,
        isVerified
    )

    const status = meta?.dataAccessStatus

    const hasAccess =
        myProfile ||
        [ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id].includes(status)

    const renderTabContent = () => {
        if (subTab === PROJECT_TYPES.HISTORICAL) {
            return <HistoricalProjectList {...props} />
        } else {
            return <ProjectList {...props} profilePage={true} />
        }
    }

    if (profilePage && !hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.PROJECT}
                title="general.diveProjects"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profileId={userID}
                checkAccessControl={fetchData}
                profile={user}
            />
        )
    }

    return (
        <div>
            <div className="-mb20">
                <div className="aligned-center a-tabName">
                    <span className="a-bodyTextMedium ">
                        {t('general.diveProjects')}
                    </span>
                </div>
            </div>
            <TabNavigation
                tabs={[
                    {
                        key: PROJECT_TYPES.REGULAR,
                        title: 'general.diveProjects',
                        queryParams: getEntityFilters(FILTERS.DIVE_PROJECTS),
                    },
                    {
                        key: PROJECT_TYPES.HISTORICAL,
                        title: 'general.historicalDiveProjects',
                        queryParams:
                            getEntityFilters(
                                FILTERS.HISTORICAL_DIVE_PROJECTS
                            ) || `?tab=${tab}`,
                    },
                ]}
                tabQueryParam="subTab"
            />
            {renderTabContent()}
        </div>
    )
}

ProjectListWrapper.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    projectStatus: PropTypes.array,
    myProfile: PropTypes.bool,
    isHistoricalProject: PropTypes.bool,
    user: PropTypes.object,
}

ProjectListWrapper.defaultProps = {
    profilePage: false,
    myProfile: false,
    isHistoricalProject: false,
}

export default ProjectListWrapper
