import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { GoogleMap, useJsApiLoader } from '@react-google-maps/api'
import { useEffect, useRef, useState } from 'react'

import { GOOGLE_MAPS_API_KEY, MAP_ID, libraries } from 'constants/constants'

import Separator from './Separator'
import FieldLabel from './FieldLabel'
import ICONS from '../constants/icons'
import COLORS from '../constants/colors'
import { BUTTON_SIZE, BUTTON_STATUS, ICON_SIZE } from '../constants/enums'
import Button from './Button'

const mapContainerStyle = {
    width: '100vw',
    height: '350px',
}

const mapId = MAP_ID

const LocationMap = ({
    value,
    setValue,
    label,
    description,
    required,
    errorMessage,
    ...props
}) => {
    const t = useTranslate()

    const { isLoaded, loadError } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries: libraries,
        async: true,
    })

    const mapRef = useRef(null)
    const [map, setMap] = useState(null)
    const [marker, setMarker] = useState(null)
    const [center, setCenter] = useState({ lat: 54.526, lng: 15.2551 }) // Default center
    const zoom = 3

    useEffect(() => {
        // Initialize the marker position with form values if they exist
        if (value && value.geoLat && value.geoLong && isLoaded) {
            if (marker) {
                marker.setMap(null) // Remove the existing marker
            }
            const newMarker =
                new window.google.maps.marker.AdvancedMarkerElement({
                    position: {
                        lat: Number(value.geoLat),
                        lng: Number(value.geoLong),
                    },
                    map: map,
                })
            setMarker(newMarker)
            setCenter({ lat: Number(value.geoLat), lng: Number(value.geoLong) })
        }
    }, [value, map])

    const onLoad = (map) => {
        mapRef.current = map
        setMap(map)

        if (value && value.geoLat && value.geoLong) {
            setMarker(
                new window.google.maps.marker.AdvancedMarkerElement({
                    map: map,
                    position: {
                        lat: Number(value.geoLat),
                        lng: Number(value.geoLong),
                    },
                })
            )
        }
    }

    const onUnmount = () => {
        if (marker) {
            marker.setMap(null)
            setMarker(null)
        }
        mapRef.current = null
        setMap(null)
    }

    const handleMapClick = (event) => {
        const newPosition = {
            lat: event.latLng.lat(),
            lng: event.latLng.lng(),
        }
        setValue({
            geoLat: newPosition.lat,
            geoLong: newPosition.lng,
        })
        if (marker) {
            marker.position = newPosition
        } else {
            const newMarker =
                new window.google.maps.marker.AdvancedMarkerElement({
                    position: newPosition,
                    map: map,
                })
            setMarker(newMarker)
            setCenter(newPosition)
        }
    }

    const resetValue = () => {
        setValue({
            geoLat: '',
            geoLong: '',
        })
        if (marker) {
            marker.setMap(null)
        }
    }

    if (loadError) {
        return <div>{t('general.errorLoadingMaps')}</div>
    }

    if (!isLoaded) {
        return <div>{t('general.loadingMaps')}</div>
    }

    return (
        <div>
            <div
                scroll-attribute="locationErrorScroll"
                className="a-mediumText a-lightText -mb5"
            >
                <FieldLabel
                    htmlFor={props.name}
                    label={label || `form.label.${props.name}`}
                    required={required}
                />
            </div>
            <div className="a-captionsTextRegular a-lightText -opacity60 -mb10">
                <span>{t(description)}</span>
            </div>
            {errorMessage && (
                <span className=" errorMessage -active -mb15">
                    {errorMessage}
                </span>
            )}
            {value && value.geoLat && value.geoLong && (
                <div className="-mb20">
                    <Button
                        btnClass={BUTTON_STATUS.TERTIARY}
                        icon={ICONS.CLEAR}
                        iconColor={COLORS.LIGHT_BLUE}
                        iconSize={ICON_SIZE.SIZE20}
                        label={'button.clearMapLocation'}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        onClick={resetValue}
                    />
                </div>
            )}
            <div className="_w _12 googleMaps">
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    zoom={zoom}
                    center={center}
                    id={mapId}
                    options={{
                        disableDefaultUI: true,
                        zoomControl: true,
                        mapId: mapId,
                    }}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    onClick={handleMapClick}
                ></GoogleMap>
            </div>
            <div className="_w -mt15">
                <Separator />
            </div>
        </div>
    )
}

export const LocationMapMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    description: PropTypes.string,
    required: PropTypes.bool,
}

LocationMap.propTypes = {
    ...LocationMapMainPropTypes,
    value: PropTypes.object,
    errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default LocationMap
