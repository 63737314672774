import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import { AlertContext } from '../../contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'
import { useRemoveFromList } from 'hooks/useRemoveUser'

import { getProfileUserStatusColor } from 'utils/profileUserStatusColors'
import { userFilters } from 'utils/userFiltersHelper'

import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import {
    ALERT_TYPES,
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'

import { createEntityService } from 'services/entity.service'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import { DiverFilters } from './index'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import {
    JOBS_SKILL_CATEGORY_TYPE_KEY,
    SKILL_TYPE_KEY,
} from 'constants/projectFilterKeys'
import React, { useContext, useEffect } from 'react'
import CardHeader from 'components/card/CardHeader'
import Pagination from '../table/Pagination'
import EmptyList from '../EmptyList'

import { mockDiversData } from 'data/mockData'

const DiverList = ({ fetchFavorites, divers, setDivers, changeUserArray }) => {
    const { currentUser, isVerified } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)
    const removeUser = useRemoveFromList()

    const t = useTranslate()
    const navigate = useNavigate()

    const { search } = useLocation()

    const { data, meta, isLoading } = useFetchDataByQueryParams(
        ENTITIES.DIVERS_ELASTIC,
        {},
        false,
        isVerified
    )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        userFilters,
        FILTERS.DIVERS
    )

    useEffect(() => {
        setDivers([
            ...divers,
            ...data.filter((item) => !divers.some((el) => el.id === item.id)),
        ])
    }, [data])

    useEffect(() => {
        setDivers([])
    }, [search])

    const formatWorkerLanguages = (workerLanguages) => {
        if (!workerLanguages?.length) return 'N\\A'

        return workerLanguages?.map(
            ({ languageName, languageProficiencyName }) =>
                `${languageName} (${languageProficiencyName.toLowerCase()})`
        )
    }

    const formatExperiences = (experiences) => {
        const allAreSlashes = experiences.every(
            ({ divingModeName }) => divingModeName === '/'
        )

        if (!experiences || !experiences.length || allAreSlashes) {
            return 'N\\A'
        }

        try {
            return experiences.map(
                ({ dives, days, bellRuns, diveModeName }) =>
                    `${diveModeName} ${
                        dives !== undefined && dives
                            ? dives + ' ' + t('general.dives')
                            : ''
                    } ${
                        days !== undefined && days
                            ? days + ' ' + t('general.days')
                            : ''
                    } ${
                        bellRuns !== undefined && bellRuns
                            ? bellRuns + ' ' + t('general.bellRuns')
                            : ''
                    }`
            )
        } catch (e) {
            return 'N\\A'
        }
    }

    const formatPositions = (positions) => {
        return positions.map(({ positionName }) => positionName)
    }

    const addToFavorites = async (item) => {
        try {
            await createEntityService(ENTITIES.FAVORITE_USER, {
                user: currentUser,
                favorite: {
                    id: item.id,
                    entityType: ENTITIES.USER,
                },
            })

            setAlert(t('message.userAddedToFavorites'), ALERT_TYPES.SUCCESS)
            changeUserArray(item, divers, setDivers)
            await fetchFavorites()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === JOBS_SKILL_CATEGORY_TYPE_KEY &&
            filters.hasOwnProperty(SKILL_TYPE_KEY)
        ) {
            delete filters[SKILL_TYPE_KEY]
        }

        return filters
    }

    const handleViewBtn = (item) => {
        if (!isVerified) return

        navigate(`${ROUTES.DIVER_PROFILE}/${item?.profileHash}`)
    }

    const handleFavoriteUser = (item) => {
        if (!isVerified) return

        item.favoriteUser
            ? removeUser(item, data, setDivers, fetchFavorites, divers)
            : addToFavorites(item)
    }

    const diversData = isVerified ? divers : mockDiversData

    return (
        <div className={!isVerified ? `a-blurredContainer` : ''}>
            <SearchAndFilter
                searchPlaceholder="general.searchDiver"
                activeFilters={activeFilters}
                filters={userFilters}
                showDefaultKey={false}
                modifyFiltersOnRemove={handleFilterItemRemoved}
                disabled={!isVerified}
            >
                <DiverFilters
                    activeFilters={activeFilters}
                    disabled={!isVerified}
                />
            </SearchAndFilter>

            {diversData.map((item, index) => {
                return (
                    <div key={index} className="-mb10">
                        <CardContainer
                            link={
                                isVerified
                                    ? `${ROUTES.DIVER_PROFILE}/${item?.profileHash}`
                                    : null
                            }
                        >
                            <CardHeader
                                avatar={item.avatarPath}
                                title={item.fullName}
                                item={item}
                                statuses={[
                                    {
                                        type: 'status',
                                        name: item.status,
                                        color: getProfileUserStatusColor(
                                            item?.statusCode
                                        ),
                                    },
                                ]}
                                verifiedBadge={!!item.identityVerified}
                                placeholderIcon={ICONS.USER}
                            />
                            <Info
                                label="form.label.country"
                                value={item.countryName}
                            />
                            <Info
                                label="form.label.languages"
                                value={formatWorkerLanguages(item.language)}
                                color="pink"
                            />
                            <Info
                                label="form.label.experiencedInModes"
                                value={formatExperiences(item.experiences)}
                                color="orange"
                            />
                            {item.positions && !!item.positions.length && (
                                <Info
                                    label="form.label.positionsOnProjects"
                                    value={formatPositions(item.positions)}
                                    color="purple"
                                />
                            )}
                            <InfoRow
                                withButtons
                                alignRight={!item?.totalExperienceYears}
                            >
                                {!!item?.totalExperienceYears && (
                                    <Info
                                        label="form.label.totalYearsOfExperience"
                                        value={item?.totalExperienceYears?.toString()}
                                        color="grayLight"
                                    />
                                )}
                                <div className="aligned-center -gap20 space-between">
                                    <Button
                                        type={BUTTON_TYPE.BUTTON}
                                        label={
                                            item.favoriteUser
                                                ? 'general.removeFromFavorites'
                                                : 'general.addToFavorites'
                                        }
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.FAVORITES}
                                        iconColor={
                                            item.favoriteUser
                                                ? COLORS.LIGHT_BLUE
                                                : COLORS.WHITE
                                        }
                                        onClick={() => handleFavoriteUser(item)}
                                        buttonSize={BUTTON_SIZE.SMALL}
                                    />
                                    <Button
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        label="button.view"
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                        onClick={() => handleViewBtn(item)}
                                    />
                                </div>
                            </InfoRow>
                        </CardContainer>
                    </div>
                )
            })}
            {!isVerified && (
                <div className="column aligned-center overlayContainer">
                    <h3>{t('general.verifyYourProfile')}</h3>
                    <p className="a-bodyTextRegular a-lightText -opacity-60 text-align-center  -mt10 -mb30">
                        {t('general.contactSupportToAccessCompleteInformation')}
                    </p>
                </div>
            )}
            {divers.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.NO_DATA}
                    title={
                        meta?.totalItems
                            ? t('general.noDiverResultsOnThisPage')
                            : t('general.noDiverResults')
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noDiverResultsOnThisPageDesc')
                            : t('general.noDiverResultsDesc')
                    }
                    showSearchResult={
                        Object.keys(activeFilters).length !== 0 &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {renderLoader(isLoading, null, isLoadingFilters)}
        </div>
    )
}

DiverList.propTypes = {
    fetchFavorites: PropTypes.func,
}

export default DiverList
