import React from 'react'
import CheknDepth from '../CheknDepth'

const diveCertificationVerificationObject = {
    mainTitle: 'CHEKN-Depth®',
    mainSubTitle: 'Service summary:',
    mainDescription:
        'CHEKN-Depth® offers unparalleled commercial diver certification authentication checks to ensure the authenticity of professional diving credentials. Our service is designed to verify and authenticate certificates issued by certification agencies and issuing authorities, providing peace of mind for diving personnel and employers alike.',
    sections: [
        {
            sectionTitle: 'Key Features:',
            numberedSections: false,
            subSections: [
                {
                    title: 'Comprehensive Certification Authentication:',
                    description:
                        'We authenticate certificates from major issuing authorities including ADCI (Association of Diving Contractors International), IMCA (International Marine Contractors Association), DCBC (Diver Certification Board of Canada), ADAS (Australian Diver Accreditation Scheme), UKHSE (United Kingdom Health and Safety Executive), and more.',
                },
                {
                    title: 'Dual Authentication Process:',
                    description:
                        'Our unique approach combines digital verification techniques with human oversight. This dual-layered process ensures the highest level of accuracy and reliability.',
                },
                {
                    title: 'Trusted Verification:',
                    description:
                        'We act on behalf of both diving personnel and employers to confirm the legitimacy of diving certifications, reducing the risk of fraud and ensuring compliance with industry standards.',
                },
            ],
        },
        {
            sectionTitle: 'Validation Process',
            numberedSections: true,
            subSections: [
                {
                    title: 'Certificate Submission:',
                    description:
                        "User uploads high-resolution color scans or photos of the certificate's front and rear sides, into the Education and Training feature on the SND profile page.",
                },
                {
                    title: 'Automated Analysis:',
                    description:
                        'SND software analyzes 15 unique characteristics of the certificate to assess its validity.',
                },
                {
                    title: 'Secure Agency Connection:',
                    description:
                        'A secure connection with the issuing agency is established to communicate the analysis results.',
                },
                {
                    title: 'Agency Verification:',
                    description:
                        'The issuing agency verifies the results and confirms or denies the authenticity of the certificate.',
                },
                {
                    title: 'Profile Notation:',
                    description: `The certificate entry in the user's profile is notated with a "Verified" symbol upon successful authentication.`,
                },
                {
                    title: 'Public Visibility:',
                    description:
                        'Platform users can see that your certificate has been verified, providing transparency and trust.',
                },
                {
                    title: 'Digital Audit Record:',
                    description:
                        'SND maintains a digital audit record of the verification process, ensuring a valid check was conducted.',
                },
            ],
        },
        {
            sectionTitle: 'Why Choose Skill N Depth?',
            numberedSections: false,
            subSections: [
                {
                    title: 'Accuracy and Reliability:',
                    description:
                        'Our hybrid verification system, utilizing both advanced digital tools and expert human review, guarantees precise and trustworthy certification checks.',
                },
                {
                    title: 'Industry Expertise:',
                    description:
                        'With extensive knowledge in the field of commercial diving, our team understands the critical importance of valid and verifiable certifications.',
                },
                {
                    title: 'Peace of Mind:',
                    description:
                        'Employers can be confident in the qualifications of their diving personnel, while divers can trust that their certifications are recognized and authenticated by a reputable service.',
                },
            ],
        },
        {
            description:
                'Ensure the integrity of your commercial diving operations with the CHEKN-Depth feature of your Skill N Depth account. Trust us to authenticate and verify your certifications, so you can focus on what matters most – safe and successful diving projects.',
        },
    ],
}

const DiveCertificationVerification = () => {
    return (
        <CheknDepth
            diveCertificationVerificationObject={
                diveCertificationVerificationObject
            }
        />
    )
}

export default DiveCertificationVerification
