import React from 'react'
import PropTypes from 'prop-types'

const CheknDepth = ({ diveCertificationVerificationObject }) => {
    return (
        <div className="fullWidth column">
            <span className="a-bodyTextMedium">
                {diveCertificationVerificationObject.mainTitle}
            </span>
            <span className="a-mediumTextSemiBold -mt10">
                {diveCertificationVerificationObject.mainSubTitle}
            </span>
            <span className="a-mediumText a-lightText -mt5">
                {diveCertificationVerificationObject.mainDescription}
            </span>
            <div className="column -gap20 -mt20">
                {diveCertificationVerificationObject.sections.map(
                    (section, index) => (
                        <div key={index} className="column">
                            <span className="a-mediumTextSemiBold">
                                {section.sectionTitle}
                            </span>
                            <span className="a-mediumText a-lightText">
                                {section.description}
                            </span>
                            <ol type="1">
                                {section.subSections?.map(
                                    (subSection, index) => (
                                        <div
                                            className="-mt5 column"
                                            key={index}
                                        >
                                            <li
                                                className={`a-mediumTextSemiBold ${
                                                    section.numberedSections
                                                        ? '-liNumber'
                                                        : ''
                                                }`}
                                            >
                                                {subSection.title}
                                            </li>
                                            <ul>
                                                <li className="-mt5 a-mediumText a-lightText -liDisc -withBiggerLeftSpace">
                                                    {subSection.description}
                                                </li>
                                            </ul>
                                        </div>
                                    )
                                )}
                            </ol>
                        </div>
                    )
                )}
            </div>
        </div>
    )
}

CheknDepth.propTypes = {
    diveCertificationVerificationObject: PropTypes.object,
}

export default CheknDepth
