import ICONS from 'constants/icons'
import { ROLES } from 'constants/enums'

import PersonalInfoCard from '../components/PersonalInfoCard'
import DiveRecordList from 'screens/common/diveRecordList/DiveRecordList'
import MedicalInfoOnboarding from 'screens/diver/DiverOnboarding/MedicalInfoOnboarding'
import { TravelDocumentsOnboarding } from 'screens/diver/DiverOnboarding/TravelDocumentsOnboarding'
import TrainingRecord from '../components/TrainingRecord'
import { PersonalEquipmentOnboarding } from 'screens/diver/DiverOnboarding/PersonalEquipmentOnboarding'
import { PastEmploymentOnboarding } from 'screens/diver/DiverOnboarding/PastEmploymentOnboarding'
import { SkillsOnboarding } from 'screens/diver/DiverOnboarding/SkillsOnboarding'
import { GalleryPage } from 'screens/common/gallery'
import ExportProfileAsCv from '../components/ExportProfileAsCv'
import ProjectListWrapper from 'components/projects/ProjectsListWrapper'

const { ROLE_DIVER, ROLE_CLIENT_ORGANIZATION_ADMIN } = ROLES

export const TAB = {
    PERSONAL_INFO: 'personalInfo',
    DIVE_PROJECTS: 'diveProjects',
    DIVE_RECORDS: 'diveRecords',
    MEDICAL_INFO: 'medicalInfo',
    TRAVEL_DOCUMENTATION: 'travelDocumentation',
    TRAINING_RECORD: 'trainingRecord',
    PERSONAL_EQUIPMENT: 'personalEquipment',
    PAST_EMPLOYMENT: 'pastEmployment',
    SKILLS: 'skills',
    EXPORT_CV: 'exportCv',
    GALLERY: 'gallery',
}

export const SUB_TAB = {
    CERTIFICATES: 'certificates',
}

export const getProfileTabs = (user, myProfile) => {
    const {
        completionProgress: {
            personalInfo,
            travelDocument,
            trainingRecord,
            skill,
        },
        diver,
    } = user

    const showBadge = diver && myProfile

    const tabs = [
        {
            key: TAB.PERSONAL_INFO,
            title: 'general.personalInfo',
            badge: showBadge && !personalInfo,
            icon: `${ICONS.PERSONAL_INFO}`,
            component: PersonalInfoCard,
            roles: [ROLE_DIVER, ROLE_CLIENT_ORGANIZATION_ADMIN],
        },
        {
            key: TAB.DIVE_PROJECTS,
            title: 'general.diveProjects',
            icon: `${ICONS.DIVE_PROJECTS_OUTLINE}`,
            component: ProjectListWrapper,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.DIVE_RECORDS,
            title: 'general.diveRecords',
            icon: `${ICONS.DIVE_RECORDS}`,
            component: DiveRecordList,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.MEDICAL_INFO,
            title: 'general.medicalInfo',
            icon: `${ICONS.MEDICAL_INFO}`,
            component: MedicalInfoOnboarding,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.TRAVEL_DOCUMENTATION,
            title: 'general.travelDocumentation',
            badge: showBadge && !travelDocument,
            icon: `${ICONS.TRAVEL_DOCUMENTATION}`,
            component: TravelDocumentsOnboarding,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.TRAINING_RECORD,
            title: 'general.educationAndTraining',
            badge: showBadge && !trainingRecord,
            icon: `${ICONS.TRAINING_RECORD}`,
            component: TrainingRecord,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.PERSONAL_EQUIPMENT,
            title: 'general.personalEquipment',
            icon: `${ICONS.PERSONAL_EQUIPMENT}`,
            component: PersonalEquipmentOnboarding,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.PAST_EMPLOYMENT,
            title: 'general.employmentHistory',
            icon: `${ICONS.PAST_EMPLOYMENT}`,
            component: PastEmploymentOnboarding,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.SKILLS,
            title: 'general.experienceAndSkills',
            badge: showBadge && !skill,
            icon: `${ICONS.SKILLS}`,
            component: SkillsOnboarding,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.GALLERY,
            title: 'general.gallery',
            icon: `${ICONS.GALLERY_SIDEBAR}`,
            component: GalleryPage,
            roles: [ROLE_DIVER],
        },
        {
            key: TAB.EXPORT_CV,
            title: 'general.exportProfileAsCv',
            icon: `${ICONS.EXPORT_CV}`,
            component: ExportProfileAsCv,
            roles: [ROLE_DIVER],
        },
    ]

    // return tabs.filter(tab => tab.roles.includes(user.role))
    return tabs.filter((tab) =>
        tab.roles.includes(diver ? ROLE_DIVER : ROLE_CLIENT_ORGANIZATION_ADMIN)
    )
}
