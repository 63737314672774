import React, { useContext, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useLocation, useNavigate } from 'react-router-dom'

import { CurrentUserContext } from 'contexts/CurrentUserContext'

import { formatDate } from 'services/moment.service'

import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import { getProjectStatusColor } from 'utils/dataFormatters'
import {
    formatDateRange,
    formatGteLteRange,
    formatPayRateRange,
} from 'utils/filterButtonFormatters'

import useFetchActiveFilters from 'hooks/useFetchActiveFilters'
import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    ICON_POSITION,
    INFO_VALUE_TYPE,
} from 'constants/enums'
import {
    INDUSTRY_TYPE_KEY,
    PROJECT_TYPE_KEY,
} from 'constants/projectFilterKeys'
import COLORS from 'constants/colors'
import ENTITIES from 'constants/entities'
import FILTERS from 'constants/filters'
import ICONS from 'constants/icons'
import ROUTES from 'constants/routes'
import { PROJECT_SEARCH_FILED } from 'constants/constants'

import { mockProjectData } from 'data/mockData'

import { FeatureAccessContext } from 'contexts/FeatureAccessContext'

import Button from 'components/Button'
import CardContainer from 'components/CardContainer'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import SearchAndFilter from 'components/SearchAndFilter'
import ProjectFilters from './ProjectFilters'
import AddNew from 'screens/diver/DiverOnboarding/AddNew'
import CardHeader from 'components/card/CardHeader'
import ProjectVisibilityIcon from 'components/icons/ProjectVisibilityIcon'
import UnverifiedDiverCardOverlay from 'components/UnverifiedDiverCardOverlay'
import Pagination from '../table/Pagination'
import EmptyList from '../EmptyList'
import useQueryParams from '../../hooks/useQueryParams'

const filters = {
    company: { entityType: ENTITIES.COMPANY, displayAttribute: 'name' },
    country: {
        entityType: ENTITIES.COUNTRY,
        icon: ICONS.LOCATION,
        displayAttribute: 'name',
    },
    industryType: { entityType: ENTITIES.INDUSTRY, displayAttribute: 'name' },
    projectType: {
        entityType: ENTITIES.PROJECT_INDUSTRY_TYPES,
        displayAttribute: 'name',
    },
    divingMode: { entityType: ENTITIES.DIVING_MODE, displayAttribute: 'name' },
    region: { entityType: ENTITIES.REGION, displayAttribute: 'name' },
    locationType: {
        entityType: ENTITIES.LOCATION_TYPE,
        displayAttribute: 'name',
    },
    locationDetail: { type: 'text' },
    keyword: { type: 'text' },
    name: { type: 'text' },
    fromDate: { type: 'dateRange', formatter: formatDateRange },
    toDurationDays: {
        type: 'range',
        unit: 'days',
        formatter: formatGteLteRange,
    },
    positionPayRate: {
        entityType: ENTITIES.DIVER_POSITION,
        sign: '$',
        formatter: formatPayRateRange,
        fetchAttribute: 'position',
    },
}

const ProjectList = ({ userID, profilePage, companyID, myProfile }) => {
    const t = useTranslate()
    const navigate = useNavigate()
    const location = useLocation()
    const { pathname } = useLocation()

    const { userId, isDiver, isVerified } = useContext(CurrentUserContext)

    const { canCreateProject } = useContext(FeatureAccessContext)

    const showMockData = !isVerified && !myProfile

    const { data, meta, isLoading, fetchData } =
        useFetchDataByQueryParams(
            ENTITIES.PROJECT_ELASTIC,
            {
                'company.id': myProfile ? null : companyID,
                myDiveProjects: myProfile || null,
                include:
                    'company,divingMode,country,locationType,projectStatus,creator',
                acceptedForUser: !myProfile && profilePage ? userID : null,
            },
            false,
            isVerified
        )

    const { activeFilters, isLoadingFilters } = useFetchActiveFilters(
        filters,
        FILTERS.DIVE_PROJECTS
    )

    const projectData = showMockData ? mockProjectData : data

    const handleEdit = ({ id }) => {
        navigate(`${ROUTES.PROJECT}${ROUTES.EDIT}/${id}`)
    }

    const handleGoToCreateProject = () => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}${ROUTES.CREATE}`)
        } else {
            navigate(`${ROUTES.PROJECT}${ROUTES.CREATE}`)
        }
    }

    const handleFilterItemRemoved = (key, filters) => {
        if (
            key === INDUSTRY_TYPE_KEY &&
            filters.hasOwnProperty(PROJECT_TYPE_KEY)
        ) {
            delete filters[PROJECT_TYPE_KEY]
        }

        return filters
    }

    const handleOpenSingleProject = (item) => {
        if (location.pathname.includes(ROUTES.MY_DIVE_PROJECTS)) {
            navigate(`${ROUTES.MY_DIVE_PROJECTS}/${item.id}`)
        } else {
            navigate(`${ROUTES.PROJECT}/${item.id}`)
        }
    }

    const getActions = (item) => {
        if (item.creatorId !== userId) {
            return []
        }

        return [
            {
                handleAction: handleEdit,
                icon: ICONS.EDIT,
                iconColor: COLORS.DARK_BLUE,
                tooltip: 'button.editProject',
            },
        ]
    }

    return (
        <div className={showMockData ? `a-blurredContainer` : ''}>
            {profilePage ? (
                <div className="-mb20">
                    {myProfile &&
                        !isDiver &&
                        isVerified &&
                        canCreateProject && (
                            <AddNew
                                handleAction={handleGoToCreateProject}
                                label="button.addDiveProject"
                            />
                        )}
                </div>
            ) : (
                <SearchAndFilter
                    searchPlaceholder="general.searchProject"
                    activeFilters={activeFilters}
                    filters={filters}
                    modifyFiltersOnRemove={handleFilterItemRemoved}
                    searchKey={PROJECT_SEARCH_FILED}
                    showDefaultKey={false}
                    disabled={showMockData}
                >
                    <ProjectFilters
                        activeFilters={activeFilters}
                        myProfile={myProfile}
                        disabled={showMockData}
                    />
                </SearchAndFilter>
            )}

            {projectData.map((item, index) => (
                <div key={index} className="-mb10">
                    <CardContainer
                        link={
                            !showMockData
                                ? `${
                                    pathname.includes(
                                        ROUTES.MY_DIVE_PROJECTS,
                                    )
                                        ? ROUTES.MY_DIVE_PROJECTS
                                        : ROUTES.PROJECT
                                }/${item.id}`
                                : null
                        }
                    >
                        <CardHeader
                            title={item?.name}
                            item={item}
                            statuses={[
                                {
                                    type: 'status',
                                    name: [item?.locationTypeName],
                                    color: 'green',
                                },
                                {
                                    type: 'status',
                                    name: [item?.projectStatusName],
                                    color: getProjectStatusColor(
                                        item?.projectStatusCode,
                                    ),
                                },
                            ]}
                            actions={getActions(item)}
                            customIcon={
                                <ProjectVisibilityIcon
                                    creatorId={item.creatorId}
                                    hidden={item.hidden}
                                />
                            }
                        />
                        <Info
                            label="form.label.contractor"
                            value={item?.companyName}
                            companyID={item?.companyProfileHash}
                            valueType={
                                !showMockData
                                    ? INFO_VALUE_TYPE.CONTRACTOR_LINK
                                    : null
                            }
                        />
                        <Info
                            label="form.label.divingMode"
                            value={item?.divingModeName}
                            color="orange"
                        />
                        {(item?.fromDate || item?.durationTo) && (
                            <InfoRow>
                                {item?.fromDate && (
                                    <Info
                                        label="form.label.projectStart"
                                        value={formatDate(item?.fromDate)}
                                    />
                                )}
                                {item?.durationTo && (
                                    <Info
                                        label="form.label.expectedDuration"
                                        value={item?.durationTo}
                                    />
                                )}
                            </InfoRow>
                        )}
                        <InfoRow withButtons>
                            <Info
                                label="form.label.countryOfWork"
                                value={item?.countryName}
                            />
                            {!showMockData && (
                                <div>
                                    <Button
                                        label="button.viewProject"
                                        buttonSize={BUTTON_SIZE.SMALL}
                                        onClick={() =>
                                            handleOpenSingleProject(item)
                                        }
                                        btnClass={BUTTON_STATUS.TERTIARY}
                                        icon={ICONS.ARROW_RIGHT}
                                        iconColor={COLORS.LIGHT_BLUE}
                                        iconPosition={ICON_POSITION.RIGHT}
                                    />
                                </div>
                            )}
                        </InfoRow>
                    </CardContainer>
                </div>
            ))}
            {showMockData && <UnverifiedDiverCardOverlay />}
            {projectData.length === 0 && !isLoading && (
                <EmptyList
                    icon={ICONS.NO_DATA}
                    title={
                        meta?.totalItems
                            ? t('general.noProjectResultsOnThisPage')
                            : t('general.noProjectResults')
                    }
                    description={
                        meta?.totalItems
                            ? t('general.noProjectResultsOnThisPageDesc')
                            : t('general.noProjectResultsDesc')
                    }
                    showSearchResult={
                        Object.keys(activeFilters).length !== 0 &&
                        !meta?.totalItems
                    }
                />
            )}
            <Pagination totalItems={meta?.totalItems} meta={meta} scrollToTop />
            {renderLoader(isLoading, profilePage, isLoadingFilters)}
        </div>
    )
}

ProjectList.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    companyID: PropTypes.number,
    myProfile: PropTypes.bool,
}

ProjectList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default ProjectList
