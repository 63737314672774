import React from 'react'
import OfflineMode from './OfflineMode'

const offlineModeObject = {
    mainTitle: 'Offline mode',
    mainDescription:
        'Offline Mode allows you to continue using the app when you don’t have an active internet connection. However, some functionalities are restricted to ensure data integrity and optimal performance. Below is a detailed guide on what is supported and what is not when you’re offline.',
    sections: [
        {
            title: 'Functionalities and Their Status in Offline Mode',
            contents: [
                {
                    title: 'Home',
                    status: 'Disabled Completely.',
                    whatHappens: [
                        'The Home page won’t load new data.',
                        'Instead, it will display the latest cached projects from your last online session.',
                    ],
                    whatYouCanDo: ['View cached projects basic data only.'],
                    whatYouCantDo:
                        'Interact with live data or load new projects.',
                },
                {
                    title: 'Notifications',
                    status: 'Disabled Completely.',
                    whatHappens: [
                        'Notifications won’t update or show new activity.',
                        'You can view cached notifications from your last session.',
                    ],
                    whatYouCanDo: [
                        'See old notifications saved from your last session.',
                    ],
                    whatYouCantDo:
                        'Receive new notifications or interact with existing ones.',
                },
                {
                    title: ' Search (Top Bar)',
                    status: 'Disabled Completely.',
                    whatHappens: [
                        'The search bar is non-functional, and clicking on it will not perform any action.',
                    ],
                    whatYouCanDo: [
                        'Nothing; search requires an active connection.',
                    ],
                    whatYouCantDo: 'Search for new data or cached data.',
                },
                {
                    title: 'Messages',
                    status: 'Disabled Completely.',
                    whatHappens: [
                        'The Messages page will display an empty screen.',
                    ],
                    whatYouCanDo: [
                        'Nothing; Chat requires an active connection.',
                    ],
                    whatYouCantDo:
                        'View conversations or send/receive messages.',
                },
                {
                    title: 'My profile',
                    status: 'Partially Available.',
                    whatHappens: [
                        'Only a limited subset of functionalities is accessible.',
                    ],
                    whatYouCanDo: [
                        'Explore SkillNDepth',
                        'Support',
                        'FAQ',
                        'Logout',
                    ],
                    whatYouCantDo:
                        'View or edit your profile details or any other advanced options.',
                },
                {
                    title: 'My Projects',
                    status: 'Partially Available.',
                    whatHappens: [
                        'Projects are displayed using cached data from the last session.',
                        'Filtering options are disabled.',
                    ],
                    whatYouCanDo: ['View cached projects basic data.'],
                    whatYouCantDo:
                        'Apply filters or interact with live project data.',
                },
                {
                    title: 'My Dive Records',
                    status: 'Partially Available.',
                    whatHappens: [
                        'Cached dive records are displayed, but some functionalities are restricted:',
                    ],
                    whatHappensList: [
                        'Copy Dive Record: Disabled.',
                        'Search and Filter: Disabled.',
                        'Delete: Disabled.',
                    ],
                    whatYouCanDo: [
                        'Create new dive records and edit them.',
                        'View cached dive records.',
                    ],
                    whatYouCantDo:
                        'Copy, search, filter, or delete dive records.',
                },
            ],
        },
        {
            note: 'Dive records created while offline cannot be continued or edited on other devices or through the browser. This functionality will be available in future versions.',
        },
        {
            title: 'Additional Tips for Offline Mode',
            additionalTips: [
                {
                    title: 'Ensure Cached Data is Updated:',
                    description:
                        'Before going offline, make sure your data is synced and updated by accessing dive records that you have created in offline mode.',
                },
                {
                    title: 'Reconnecting to the Internet:',
                    description:
                        'Once you reconnect, all disabled functionalities will be restored, and cached data will be refreshed automatically.',
                },
            ],
        },
    ],
}

const OfflineModePage = () => {
    return <OfflineMode offlineModeObject={offlineModeObject} />
}

export default OfflineModePage
