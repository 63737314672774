import React, { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import { deleteEntityService } from 'services/entity.service'
import { formatDate, MOMENT_FORMATS } from 'services/moment.service'

import { getProjectStatusColor } from 'utils/dataFormatters'

import useFetchDataByQueryParams from 'hooks/useFetchDataByQueryParams'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, INFO_VALUE_TYPE } from 'constants/enums'
import { ConfirmationModalContext } from 'contexts/ConfirmationModalContext'
import { AlertContext } from 'contexts/AlertContext'
import ICONS from 'constants/icons'
import COLORS from 'constants/colors'

import CardContainer from 'components/CardContainer'
import CardHeader from 'components/card/CardHeader'
import Info from 'components/Info'
import InfoRow from 'components/InfoRow'
import EditHistoricalProjectModal from 'screens/diver/profile/diverInformation/modals/EditHistoricalProjectModal'
import CreateHistoricalProjectModal from 'screens/diver/profile/diverInformation/modals/CreateHistoricalProjectModal'
import renderLoader from 'screens/diver/DiverOnboarding/utils/loaderHelper'
import ProjectVisibilityIcon from 'components/icons/ProjectVisibilityIcon'
import ExpendableText from 'components/ExpendableText'
import EmptyList from '../EmptyList'
import Pagination from '../table/Pagination'
import { useTranslate } from 'react-polyglot'
import { CurrentUserContext } from '../../contexts/CurrentUserContext'
import useFetchActiveFilters from '../../hooks/useFetchActiveFilters'
import FILTERS from '../../constants/filters'

const HistoricalProjectList = (props) => {
    const { myProfile, profilePage, userID } = props
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)
    const { showConfirmationModal, closeConfirmationModal } = useContext(
        ConfirmationModalContext
    )
    const { userId, isVerified } = useContext(CurrentUserContext)
    const [historicalItem, setHistoricalItem] = useState(null)
    const [openHistoricalProjectModal, setOpenHistoricalProjectModal] =
        useState(false)
    const showMockData = !isVerified && !myProfile

    const { data, meta, isLoading, fetchData } = useFetchDataByQueryParams(
        ENTITIES.HISTORICAL_PROJECT,
        {
            creator_id: !myProfile && profilePage ? userID : userId,
        },
        false,
        true
    )

    useFetchActiveFilters({}, FILTERS.HISTORICAL_DIVE_PROJECTS)

    const handleEditHistorical = (historicalProject) => {
        setHistoricalItem(historicalProject)
        setOpenHistoricalProjectModal(true)
    }

    const handleDeleteHistorical = async (id) => {
        try {
            await deleteEntityService(ENTITIES.HISTORICAL_PROJECT, id)
            closeConfirmationModal()
            setAlert(t('message.successfullyDeleted'), ALERT_TYPES.SUCCESS)
            await fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }

    const handleDeleteHistoricalConfirmation = ({ id }) =>
        showConfirmationModal({
            message: 'message.areYouSureDeleteHistoricalProject',
            title: 'button.deleteHistoricalProject',
            handleConfirm: () => handleDeleteHistorical(id),
            handleCancel: () => closeConfirmationModal(),
        })

    const getActions = (item) =>
        item.creatorId !== userId
            ? []
            : [
                  {
                      handleAction: handleEditHistorical,
                      icon: ICONS.EDIT,
                      iconColor: COLORS.DARK_BLUE,
                      tooltip: 'button.editProject',
                  },
                  {
                      handleAction: (item) =>
                          handleDeleteHistoricalConfirmation(item),
                      icon: ICONS.DELETE,
                      iconColor: COLORS.RED,
                      tooltip: 'button.deleteProject',
                  },
              ]

    return (
        <div>
            <div className="-mb20">
                {!historicalItem && profilePage && myProfile && (
                    <div className="justify-center -mb20">
                        <CreateHistoricalProjectModal
                            fetchHistoricalProjects={fetchData}
                            openHistoricalModal={openHistoricalProjectModal}
                            setOpenHistoricalProjectModal={
                                setOpenHistoricalProjectModal
                            }
                            setHistoricalItem={setHistoricalItem}
                        />
                    </div>
                )}
                {data.map((item, index) => (
                    <div key={index} className="-mb10">
                        <CardContainer>
                            <CardHeader
                                title={item?.name}
                                item={item}
                                statuses={[
                                    {
                                        type: 'status',
                                        name: [item?.locationTypeName],
                                        color: 'green',
                                    },
                                    {
                                        type: 'status',
                                        name: [item?.projectStatusName],
                                        color: getProjectStatusColor(
                                            item?.projectStatusCode
                                        ),
                                    },
                                ]}
                                actions={getActions(item)}
                                customIcon={
                                    <ProjectVisibilityIcon
                                        creatorId={item.creatorId}
                                        hidden={item.hidden}
                                    />
                                }
                            />
                            <Info
                                label="form.label.contractor"
                                value={item?.companyName}
                                companyID={item?.companyProfileHash}
                                valueType={
                                    !showMockData
                                        ? INFO_VALUE_TYPE.CONTRACTOR_LINK
                                        : null
                                }
                            />
                            <Info
                                label="form.label.divingMode"
                                value={item?.divingModeName}
                                color="orange"
                            />
                            <InfoRow withButtons>
                                <Info
                                    label="form.label.countryOfWork"
                                    value={item?.countryName}
                                />
                            </InfoRow>
                            {item?.regionId && (
                                <Info
                                    label="form.label.geographicalRegion"
                                    value={item?.regionName}
                                />
                            )}

                            {item?.locationDetail && (
                                <Info
                                    label="form.label.locationDetail"
                                    value={item?.locationDetail}
                                />
                            )}

                            {item?.nameOfVessel && (
                                <Info
                                    label="form.label.divingVessel"
                                    value={item?.nameOfVessel}
                                />
                            )}

                            {item?.positionId && (
                                <Info
                                    label="form.label.teamPositions"
                                    value={item?.positionName}
                                />
                            )}
                            {item?.projectScope && (
                                <ExpendableText
                                    title={'form.label.scopeOfWork'}
                                    text={item?.projectScope}
                                />
                            )}
                            {(item?.fromDate || item?.toDate) && (
                                <InfoRow>
                                    {item?.fromDate && (
                                        <Info
                                            label="form.label.projectStart"
                                            value={formatDate(
                                                item?.fromDate,
                                                MOMENT_FORMATS.DATE_MONTHYEAR
                                            )}
                                        />
                                    )}
                                    {item?.toDate && (
                                        <Info
                                            label="form.label.projectEnd"
                                            value={formatDate(
                                                item?.toDate,
                                                MOMENT_FORMATS.DATE_MONTHYEAR
                                            )}
                                        />
                                    )}
                                </InfoRow>
                            )}
                        </CardContainer>
                    </div>
                ))}
                {data.length === 0 && !isLoading && (
                    <EmptyList
                        icon={ICONS.NO_DATA}
                        title={t(
                            'general.noHistoricalProjectResultsOnThisPage'
                        )}
                        description={t(
                            'general.noHistoricalProjectResultsOnThisPageDesc'
                        )}
                    />
                )}
                <Pagination
                    totalItems={meta?.totalItems}
                    meta={meta}
                    scrollToTop
                />
                {renderLoader(isLoading, profilePage)}
                {historicalItem && (
                    <EditHistoricalProjectModal
                        initialData={historicalItem}
                        fetchHistoricalProjects={fetchData}
                        openHistoricalModal={openHistoricalProjectModal}
                        setOpenHistoricalProjectModal={
                            setOpenHistoricalProjectModal
                        }
                        setHistoricalItem={setHistoricalItem}
                    ></EditHistoricalProjectModal>
                )}
            </div>
        </div>
    )
}

HistoricalProjectList.propTypes = {
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
}

HistoricalProjectList.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default HistoricalProjectList
