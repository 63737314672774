import React from 'react'
import { useTranslate } from 'react-polyglot'
import PropTypes from 'prop-types'

import COLORS from 'constants/colors'
import { ICON_SIZE } from 'constants/enums'
import ICONS from 'constants/icons'

import Icon from 'components/Icon'

const AddNew = ({
    handleAction,
    label,
    title,
    subtitle,
    required,
    disabled,
    withoutTopMargin,
}) => {
    const t = useTranslate()

    return (
        <div
            className={`${withoutTopMargin ? '' : '-mt20'} m-addAndUpload ${
                disabled ? '-disabled' : ''
            }`}
        >
            {title && (
                <h6 className="m-addAndUpload__title">
                    <span className="-title"> {t(title)}</span>
                    {subtitle && (
                        <span className="-subtitle a-captionsTextRegular a-lightText -opacity-60">
                            {`(${t(subtitle)}`}
                            {required && <span className="redStar">*</span>}
                            {`)`}
                        </span>
                    )}
                </h6>
            )}
            <div
                className="m-addAndUpload__box"
                onClick={disabled ? () => {} : handleAction}
            >
                <div className="a-file _12">
                    <div className="icon">
                        <Icon
                            name={ICONS.PLUS}
                            size={ICON_SIZE.SIZE20}
                            color={
                                !disabled
                                    ? COLORS.LIGHT_BLUE
                                    : COLORS.DARK_BLUE_40
                            }
                        />
                    </div>
                    <p className="a-mediumText -descriptionText">{t(label)}</p>
                </div>
            </div>
        </div>
    )
}

AddNew.propTypes = {
    handleAction: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    withoutTopMargin: PropTypes.bool,
}

AddNew.defaultProps = {
    disabled: false,
    withoutTopMargin: false,
}

export default AddNew
