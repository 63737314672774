import PropTypes from 'prop-types'
import { Fragment, useState } from 'react'
import { useTranslate } from 'react-polyglot'

import useFetchData from 'hooks/useFetchData'
import useEmptyValueMessage from 'hooks/useEmptyValueMessage'

import ENTITIES from 'constants/entities'
import {
    ACCESS_CONTROL_MODULES,
    ACCESS_CONTROL_STATUSES,
} from 'constants/enums'
import ICONS from 'constants/icons'

import { PastEmployments, CreatePastEmployment } from './'
import Loader from 'components/Loader'
import StepButtons from 'components/StepButtons'
import AddNew from '../AddNew'
import RequestAccess from 'screens/diver/profile/RequestAccess'
import NoData from 'components/NoData'

const PastEmploymentOnboarding = ({
    goToNextStep,
    goPreviousStep,
    userID,
    myProfile,
    profilePage,
    user,
}) => {
    const t = useTranslate()

    const [create, setCreate] = useState(false)

    const showActions = myProfile || !profilePage

    const { data, meta, isLoading, fetchData } = useFetchData(
        ENTITIES.PAST_EMPLOYMENT,
        {
            include: 'files,employer,pastEmploymentPosition,industry',
            'user.id': userID,
        },
        true,
        false
    )

    const status = meta?.dataAccessStatus
    const hasAccess =
        myProfile ||
        [
            ACCESS_CONTROL_STATUSES.GRANTED_WITH_FILES.id,
            ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id,
        ].includes(status)

    const hideFiles =
        status === ACCESS_CONTROL_STATUSES.GRANTED_WITHOUT_FILES.id

    const { showEmptyValueMessage } = useEmptyValueMessage(!showActions)

    const handleOpenModal = () => setCreate(true)

    if (!hasAccess) {
        return (
            <RequestAccess
                module={ACCESS_CONTROL_MODULES.PAST_EMPLOYMENT}
                title="general.employmentHistory"
                status={status}
                canRequestAccess={
                    status === ACCESS_CONTROL_STATUSES.NOT_FOUND.id
                }
                profile={user}
                checkAccessControl={fetchData}
                requestTime={meta.dataAccessRequestTime}
            />
        )
    }

    return (
        <>
            <div className={profilePage ? 'm-profileTabs' : '-contentElements'}>
                {profilePage ? (
                    <div className="aligned-center a-tabName">
                        <span className="a-bodyTextMedium ">
                            {t('general.employmentHistory')}
                        </span>
                    </div>
                ) : (
                    <Fragment>
                        <h2 className="-mt35 _12 a-centeredText">
                            {t('general.employmentHistory')}
                        </h2>
                        <div className="-description -mt20 _12">
                            <p className="a-bodyTextRegular a-centeredText -descriptionText">
                                {t('general.listOfEmploymentHistory')}
                            </p>
                        </div>
                    </Fragment>
                )}
                {hideFiles && (
                    <p className="a-mediumText a-lightText -mt20">
                        {t('general.noAccessToFiles')}
                    </p>
                )}
                {showActions && (
                    <div className={!profilePage ? '_12' : ''}>
                        <AddNew
                            handleAction={handleOpenModal}
                            label="general.addANewEmployment"
                        />
                    </div>
                )}

                {create && (
                    <CreatePastEmployment
                        open={create}
                        setOpen={setCreate}
                        fetchData={fetchData}
                    />
                )}

                <PastEmployments
                    data={data}
                    isLoading={isLoading}
                    fetchData={fetchData}
                    showActions={showActions}
                    profilePage={profilePage}
                />
            </div>

            {!profilePage && (
                <StepButtons
                    handleBack={goPreviousStep}
                    handleNext={goToNextStep}
                    handleSkip={goToNextStep}
                    canSkip={!data.length}
                    noBorder
                />
            )}

            {showEmptyValueMessage && data?.length === 0 && !isLoading && (
                <NoData
                    icon={ICONS.NO_DATA}
                    title={t('general.noDataYet')}
                    description={t('general.nothingHereRightNow')}
                />
            )}

            {isLoading && data.length > 0 && <Loader />}
        </>
    )
}

PastEmploymentOnboarding.propTypes = {
    goToNextStep: PropTypes.func,
    goPreviousStep: PropTypes.func,
    profilePage: PropTypes.bool,
    userID: PropTypes.number,
    myProfile: PropTypes.bool,
}

PastEmploymentOnboarding.defaultProps = {
    profilePage: false,
    myProfile: false,
}

export default PastEmploymentOnboarding
