import { Form, Formik, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import { Fragment, useContext, useEffect, useRef } from 'react'
import { useTranslate } from 'react-polyglot'
import * as Yup from 'yup'

import useFormikFieldChange from 'hooks/useFormikFieldChange'

import {
    MOMENT_FORMATS,
    getIsDateSameOrBefore,
    getIsDateSameOrBeforeCurrentDate,
    getTodaysDate,
    getTomorrowDate,
} from 'services/moment.service'

import { AlertContext } from 'contexts/AlertContext'
import { CurrentUserContext } from 'contexts/CurrentUserContext'

import ENTITIES from 'constants/entities'
import { ALERT_TYPES, BUTTON_STATUS, BUTTON_TYPE } from 'constants/enums'
import REGEXP from 'constants/regex'

import Button from 'components/Button'
import FocusError from 'components/FocusError'
import {
    CheckboxField,
    DateTimeField,
    FileUploadField,
    InputField,
} from 'components/formFields'
import Loader from 'components/Loader'
import Modal from 'components/Modal'
import Separator from 'components/Separator'
import Note from 'components/Note'

const CertificateFormContext = ({ trainingSchoolName }) => {
    const formikContext = useFormikContext()
    const { values, setFieldValue, setFieldTouched } = formikContext
    const { notExpire } = values

    const firstRender = useRef(true)

    useEffect(() => {
        if (!firstRender.current) {
            if (notExpire) {
                setFieldValue('expiresDate', null)
                setFieldTouched('expiresDate', false)
            }
        } else {
            firstRender.current = false
        }
    }, [notExpire])

    const trainingSchollNameChangedHandler = (values, setFieldValue) => {
        setFieldValue('certificateIssuerName', trainingSchoolName)
    }

    useFormikFieldChange(
        ['trainingSchoolName'],
        trainingSchollNameChangedHandler
    )

    return null
}

const CertificateForm = ({
    handleSubmit,
    initialData,
    setOpen,
    open,
    title,
    modal,
    setShowCertificateForm,
    setCertificates,
    trainingSchoolName,
    editCertificateIndex,
}) => {
    const t = useTranslate()

    const { userId, currentUser } = useContext(CurrentUserContext)
    const { setAlert } = useContext(AlertContext)

    const initialValues = {
        certificateIssuerName:
            initialData?.certificateIssuerName ?? trainingSchoolName ?? '',
        name: initialData?.name ?? '',
        issuedDate: initialData?.issuedDate ?? '',
        expiresDate: initialData?.expiresDate ?? '',
        notExpire: initialData?.notExpire ?? false,
        certificateNumber: initialData?.certificateNumber ?? '',
        certificateUrl: initialData?.certificateUrl ?? '',
        files: initialData?.files ?? [],
        user: currentUser ?? null,
    }

    const requiredMessage = t('form.error.required')

    const validation = Yup.object({
        certificateIssuerName: Yup.string().required(requiredMessage),
        name: Yup.string().required(requiredMessage),
        issuedDate: Yup.date().max(
            getTodaysDate(true),
            t('form.error.dateOfIssuanceInFuture')
        ),
        expiresDate: Yup.date().when('notExpire', {
            is: (val) => val === false,
            then: () =>
                Yup.date()
                    .test('expiresDate', (value, { parent, createError }) => {
                        if (!parent.issuedDate) {
                            return true
                        }

                        if (
                            getIsDateSameOrBefore(
                                value,
                                parent.issuedDate,
                                MOMENT_FORMATS.DATE
                            )
                        ) {
                            return createError({
                                message: t(
                                    'form.error.expirationAfterIssueDate'
                                ),
                                path: 'expiresDate',
                            })
                        }
                        if (
                            getIsDateSameOrBeforeCurrentDate(
                                value,
                                MOMENT_FORMATS.DATE
                            )
                        ) {
                            return createError({
                                message: t('form.error.expirationDateInPast'),
                                path: 'expiresDate',
                            })
                        }
                        return true
                    })
                    .required(requiredMessage),
            otherwise: () => Yup.date().nullable(),
        }),
        certificateNumber: Yup.string(),
        certificateUrl: Yup.string().matches(
            REGEXP.REGEXP_URL,
            t('form.error.certificateURL')
        ),
        files: Yup.array().notRequired(),
    })

    const onSubmit = async ({ ...formData }, { setSubmitting }) => {
        if (modal) {
            try {
                setSubmitting(true)
                await handleSubmit({
                    ...formData,
                    user: { id: userId, entityType: ENTITIES.USER },
                })

                handleClose()
            } catch (error) {
                setAlert(error, ALERT_TYPES.ERROR)
            } finally {
                setSubmitting(false)
            }
        } else {
            if (editCertificateIndex !== null) {
                setCertificates((prevCertificates) =>
                    prevCertificates.map((certificate, index) =>
                        index === editCertificateIndex ? formData : certificate
                    )
                )
            } else {
                setCertificates((certificates) => [
                    ...certificates,
                    {
                        ...formData,
                    },
                ])
            }
            setAlert(t('message.success'), ALERT_TYPES.SUCCESS)
            setShowCertificateForm()
        }
    }

    const handleClose = () => setOpen(false)

    const renderForm = (isSubmitting, notExpire) => {
        return (
            <Fragment>
                <FocusError />
                <CertificateFormContext
                    trainingSchoolName={trainingSchoolName}
                />
                <div className={`_wr ${modal ? '' : '-certificateForm'}`}>
                    <div className="_w">
                        {!modal && <Separator />}

                        {!modal && (
                            <p className="a-bodyTextRegular -mb20 _12">
                                {t('general.certificateInformation')}
                            </p>
                        )}

                        <div className="_12 _m6">
                            <InputField name="certificateIssuerName" required />
                        </div>
                        <div className="_12 _m6">
                            <InputField
                                name="name"
                                label="form.label.certificateName"
                                placeholder="form.placeholder.certificateName"
                                required
                            />
                        </div>
                        <div className="_12 _m4">
                            <DateTimeField
                                name="issuedDate"
                                maxDate={getTodaysDate()}
                            />
                        </div>
                        <div className="_12 _s8 _m4">
                            <DateTimeField
                                name="expiresDate"
                                disabled={notExpire}
                                required
                                minDate={getTomorrowDate()}
                            />
                        </div>
                        <div className="_12 _s4 centered-checkbox -withSelect">
                            <CheckboxField
                                name="notExpire"
                                label="form.label.notExpire"
                                translate
                            />
                        </div>
                        <Separator />
                        <div className="_12 _m6">
                            <InputField name="certificateNumber" />
                        </div>
                        <div className="_12 _m6">
                            <InputField name="certificateUrl" />
                        </div>
                        <Separator />
                        <FileUploadField
                            name="files"
                            label="form.label.trainingCertificateFiles"
                        />
                        <div className="_12 -mt20">
                            <Note note="message.verificationOfTraining" />
                        </div>
                    </div>
                    {!modal && (
                        <div className="_w ">
                            <div className="_5 _m3 -mt20 fullWidthButton -left">
                                <Button
                                    btnClass={BUTTON_STATUS.SECONDARY}
                                    type={BUTTON_TYPE.BUTTON}
                                    label="button.cancel"
                                    onClick={() => setShowCertificateForm()}
                                    disabled={isSubmitting}
                                />
                            </div>
                            <div className="_7 _m4 -mt20 fullWidthButton -right">
                                <Button
                                    btnClass={BUTTON_STATUS.PRIMARY}
                                    type={BUTTON_TYPE.SUBMIT}
                                    label="button.saveThisCertificate"
                                    disabled={isSubmitting}
                                />
                            </div>
                        </div>
                    )}

                    {isSubmitting && <Loader />}
                </div>
            </Fragment>
        )
    }

    return (
        <>
            {modal ? (
                <Formik
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ values: { notExpire, issuedDate }, isSubmitting }) => (
                        <Form>
                            <Modal
                                open={open}
                                setOpen={setOpen}
                                closeOnClickOutside={false}
                                isSubmitting={isSubmitting}
                                buttons={{
                                    nextBtn: {
                                        label: 'button.saveCertificate',
                                    },
                                }}
                                title={title}
                            >
                                {renderForm(isSubmitting, notExpire)}
                            </Modal>
                        </Form>
                    )}
                </Formik>
            ) : (
                <Formik
                    enableReinitialize={true}
                    initialValues={initialValues}
                    validationSchema={validation}
                    onSubmit={onSubmit}
                >
                    {({ values: { notExpire, issuedDate }, isSubmitting }) => (
                        <Form>
                            <div className="-mt15">
                                {renderForm(isSubmitting, notExpire)}
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        </>
    )
}

CertificateForm.propTypes = {
    handleSubmit: PropTypes.func,
    initialData: PropTypes.object,
    setOpen: PropTypes.func,
    open: PropTypes.bool,
    title: PropTypes.string,
    modal: PropTypes.bool,
    setShowCertificateForm: PropTypes.func,
    setCertificates: PropTypes.func,
    trainingSchoolName: PropTypes.string,
    editCertificateIndex: PropTypes.number,
}

CertificateForm.defaultProps = {
    modal: true,
    trainingSchoolName: '',
}

export default CertificateForm
