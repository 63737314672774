import PropTypes from 'prop-types'
import Note from 'components/Note'

const OfflineMode = ({ offlineModeObject }) => {
    return (
        <div className="fullWidth column">
            <span className="a-bodyTextMedium">
                {offlineModeObject?.mainTitle}
            </span>
            <span className="a-mediumText a-lightText -mt5">
                {offlineModeObject.mainDescription}
            </span>
            <div className="-mt20 column -gap20">
                {offlineModeObject.sections.map((section, index) => (
                    <div key={index} className="column">
                        <div className="column -gap5">
                            {section.title && (
                                <span className="a-mediumTextSemiBold">
                                    {section.title}
                                </span>
                            )}
                            <div className="column -gap20">
                                {section.contents?.map((content, index) => (
                                    <div key={index} className="column -gap5">
                                        {content.title && (
                                            <span className="a-mediumTextSemiBold">
                                                {content.title}
                                            </span>
                                        )}
                                        <ul className="column -gap5">
                                            {content.status && (
                                                <li className="a-mediumText -liDisc -withBiggerLeftSpace">
                                                    <span className="a-mediumTextSemiBold">
                                                        Status:{' '}
                                                    </span>
                                                    <span className="a-lightText">
                                                        {content.status}
                                                    </span>
                                                </li>
                                            )}
                                            {content.whatHappens && (
                                                <li className="a-mediumText -liDisc -withBiggerLeftSpace">
                                                    <span className="a-mediumTextSemiBold">
                                                        What happens:{' '}
                                                    </span>
                                                    <ul>
                                                        {content.whatHappens.map(
                                                            (item, index) => (
                                                                <li
                                                                    className={`a-mediumText ${
                                                                        content
                                                                            .whatHappens
                                                                            .length >
                                                                        1
                                                                            ? '-liDisc'
                                                                            : ''
                                                                    } a-lightText`}
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                    <ul>
                                                        {content.whatHappensList?.map(
                                                            (item, index) => (
                                                                <li
                                                                    className="a-mediumText -liDisc a-lightText -withBiggerLeftSpace"
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                            {content.whatYouCanDo && (
                                                <li className="a-mediumText -liDisc -withBiggerLeftSpace">
                                                    <span className="a-mediumTextSemiBold">
                                                        What you can do:{' '}
                                                    </span>
                                                    <ul>
                                                        {content.whatYouCanDo.map(
                                                            (item, index) => (
                                                                <li
                                                                    className={`a-mediumText ${
                                                                        content
                                                                            .whatYouCanDo
                                                                            .length >
                                                                        1
                                                                            ? '-liDisc'
                                                                            : ''
                                                                    } a-lightText`}
                                                                    key={index}
                                                                >
                                                                    {item}
                                                                </li>
                                                            )
                                                        )}
                                                    </ul>
                                                </li>
                                            )}
                                            {content.whatYouCantDo && (
                                                <li className="a-mediumText -liDisc -withBiggerLeftSpace">
                                                    <div className="column">
                                                        <span className="a-mediumTextSemiBold">
                                                            What you can't do:{' '}
                                                        </span>
                                                        <span className="a-mediumText a-lightText">
                                                            {
                                                                content.whatYouCantDo
                                                            }
                                                        </span>
                                                    </div>
                                                </li>
                                            )}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                            {section.additionalTips && (
                                <ul className="-gap5 column">
                                    {section.additionalTips?.map(
                                        (tip, index) => (
                                            <div className="column" key={index}>
                                                <li className="a-mediumTextSemiBold -liDisc -withBiggerLeftSpace">
                                                    <div className="column">
                                                        <span className="a-mediumTextSemiBold">
                                                            {tip.title}
                                                        </span>
                                                        <span className="a-mediumText a-lightText">
                                                            {tip.description}
                                                        </span>
                                                    </div>
                                                </li>
                                            </div>
                                        )
                                    )}
                                </ul>
                            )}
                        </div>
                        {section.note && <Note> {section.note}</Note>}
                    </div>
                ))}
            </div>
        </div>
    )
}

OfflineMode.propTypes = {
    offlineModeObject: PropTypes.object,
}

export default OfflineMode
