import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'
import { useEffect, useRef, useState } from 'react'

import {
    BUTTON_SIZE,
    BUTTON_STATUS,
    BUTTON_TYPE,
    ICON_POSITION,
} from 'constants/enums'
import ICONS from 'constants/icons'

import Button from './Button'

const ExpendableText = ({ title, text }) => {
    const { innerWidth: width } = window
    const t = useTranslate()

    const textRef = useRef(null)
    const containerRef = useRef(null)

    const [isExpanded, setIsExpanded] = useState(false)
    const [height, setHeight] = useState(0)
    const [lineHeight, setLineHeight] = useState(0)

    useEffect(() => {
        if (containerRef.current) {
            const computedStyle = window.getComputedStyle(textRef.current)
            const lineHeightValue = parseFloat(computedStyle.lineHeight)

            setHeight(containerRef.current.scrollHeight)
            setLineHeight(lineHeightValue)
        }
    }, [text, width])

    const handleToggle = () => {
        setIsExpanded(!isExpanded)
    }

    const visibleHeight = lineHeight * 3

    const setContainerHeight = isExpanded ? `${height}px` : `${visibleHeight}px`

    return (
        <div className="a-expendableText column fullWidth">
            <span className="a-mediumText a-lightText -mb5">{t(title)}:</span>
            <div
                className="a-expendableText__container"
                ref={containerRef}
                style={{
                    height:
                        height > visibleHeight ? setContainerHeight : 'unset',
                }}
            >
                <span
                    ref={textRef}
                    style={{
                        overflow: isExpanded ? 'hidden' : 'visible',
                        WebkitLineClamp: isExpanded ? 'none' : 3,
                    }}
                    className="a-mediumText a-lightText -opacity60 -text"
                >
                    {text}
                </span>
            </div>
            {height > visibleHeight && (
                <div className="-mt10 justify-end fullWidth">
                    <Button
                        label={
                            isExpanded ? 'button.readLess' : 'button.readMore'
                        }
                        type={BUTTON_TYPE.BUTTON}
                        btnClass={BUTTON_STATUS.TERTIARY}
                        buttonSize={BUTTON_SIZE.XSMALL}
                        icon={
                            isExpanded ? ICONS.CHEVRON_UP : ICONS.CHEVRON_DOWN
                        }
                        iconPosition={ICON_POSITION.RIGHT}
                        onClick={() => {
                            handleToggle()
                        }}
                    />
                </div>
            )}
        </div>
    )
}

ExpendableText.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
}

export default ExpendableText
