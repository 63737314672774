import { Outlet, useLocation } from 'react-router-dom'
import { useTranslate } from 'react-polyglot'

import useWindowDimensions from 'hooks/useWindowDimension'

import ROUTES from 'constants/routes'
import ICONS from 'constants/icons'

import Breadcrumbs from 'components/Breadcrumbs'
import TabNavigation from 'components/tabs/TabNavigation'
import Icon from 'components/Icon'

const TABS = [
    {
        key: 'explore-skill-n-depth',
        subItemsTitle: 'general.exploreSkillNDepth',
        to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
        icon: ICONS.EXPLORE,
        subItems: [
            {
                key: 'dive-record-verification-levels',
                label: 'general.diveRecordVerificationLevels',
                to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.VERIFICATION_LEVELS}`,
                translate: true,
            },
            {
                key: 'dive-record-statuses',
                label: 'general.diveRecordStatuses',
                to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.DIVE_RECORD_STATUSES}`,
                translate: true,
            },
            {
                key: 'badges',
                label: 'badge.badges',
                to: `${ROUTES.EXPLORE_SKILL_AND_DEPTH}/${ROUTES.BADGES}`,
                translate: true,
            },
        ],
    },
    {
        key: 'how-to',
        subItemsTitle: 'general.howTo',
        to: `${ROUTES.HOW_TO}/${ROUTES.CREATE_PERSONAL_ACCOUNT}`,
        icon: ICONS.VIDEO,
        subItems: [
            {
                key: 'howToCreatePersonalAccount',
                label: 'general.howToCreatePersonalAccount',
                to: `${ROUTES.HOW_TO}/${ROUTES.CREATE_PERSONAL_ACCOUNT}`,
                translate: true,
            },
            {
                key: 'howToUpdatePersonalInformation',
                label: 'general.howToUpdatePersonalInformation',
                to: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_PERSONAL_INFORMATION}`,
                translate: true,
            },
            {
                key: 'howToVerifyIdentityUsingPersona',
                label: 'general.howToVerifyIdentityUsingPersona',
                to: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_IDENTITY_USING_PERSONA}`,
                translate: true,
            },
            {
                key: 'howToCreateHistoricalDiveRecord',
                label: 'general.howToCreateHistoricalDiveRecord',
                to: `${ROUTES.HOW_TO}/${ROUTES.CREATE_HISTORICAL_DIVE_RECORD}`,
                translate: true,
            },
            {
                key: 'howToSendDiveRecordForVerificationLevelOne',
                label: 'general.howToSendDiveRecordForVerificationLevelOne',
                to: `${ROUTES.HOW_TO}/${ROUTES.SEND_DIVE_RECORD_FOR_VERIFICATION_LEVEL_ONE}`,
                translate: true,
            },
            {
                key: 'howToVerifyDiveRecordAsASupervisorLevelOne',
                label: 'general.howToVerifyDiveRecordAsASupervisorLevelOne',
                to: `${ROUTES.HOW_TO}/${ROUTES.VERIFY_DIVE_RECORD_AS_A_SUPERVISOR_LEVEL_ONE}`,
                translate: true,
            },
            {
                key: 'howToSignUpAsOrganization',
                label: 'general.howToSignUpAsOrganization',
                to: `${ROUTES.HOW_TO}/${ROUTES.SIGN_UP_AS_ORGANIZATION}`,
                translate: true,
            },
            {
                key: 'howToUpdateOrganizationProfile',
                label: 'general.howToUpdateOrganizationProfile',
                to: `${ROUTES.HOW_TO}/${ROUTES.UPDATE_ORGANIZATION_PROFILE}`,
                translate: true,
            },
            {
                key: 'howToCreateAProject',
                label: 'general.howToCreateAProject',
                to: `${ROUTES.HOW_TO}/${ROUTES.CREATE_A_PROJECT}`,
                translate: true,
            },
        ],
    },
    {
        key: 'faq',
        title: 'general.faq',
        to: `${ROUTES.FAQ}`,
        icon: ICONS.QUESTION_MARK_ICON,
    },
    {
        key: 'support',
        title: 'general.support',
        to: `${ROUTES.SUPPORT}`,
        icon: ICONS.SUPPORT,
    },
    {
        key: 'chekn-depth',
        subItemsTitle: 'general.cheknDepth',
        to: `${ROUTES.CHEKN_DEPTH}/${ROUTES.DIVE_CERTIFICATION_VERIFICATION}`,
        icon: ICONS.CHEKN_DEPTH,
        subItems: [
            {
                key: 'dive-certification-verification',
                label: 'general.diveCertificationVerification',
                to: `${ROUTES.CHEKN_DEPTH}/${ROUTES.DIVE_CERTIFICATION_VERIFICATION}`,
                translate: true,
            },
        ],
    },
    {
        key: 'offline-mode',
        title: 'general.offlineMode',
        to: `${ROUTES.OFFLINE_MODE}`,
        icon: ICONS.OFFLINE_MODE,
    },
    {
        key: 'gallery-images-policy',
        title: 'general.galleryImagesPolicy',
        to: `${ROUTES.GALLERY_IMAGES_POLICY}`,
        icon: ICONS.GALLERY,
    },
    {
        key: 'release-notes',
        subItemsTitle: 'general.releaseNotes',
        to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_4_0}`,
        icon: ICONS.RELEASE_NOTES,
        subItems: [
            {
                key: 'release-1-4-0',
                label: 'general.release140',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_4_0}`,
                translate: true,
            },
            {
                key: 'release-1-3-0',
                label: 'general.release130',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_3_0}`,
                translate: true,
            },
            {
                key: 'release-1-2-0',
                label: 'general.release120',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_2_0}`,
                translate: true,
            },
            {
                key: 'release-1-1-0',
                label: 'general.release110',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_1_0}`,
                translate: true,
            },
            {
                key: 'release-1-0-0',
                label: 'general.release100',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_1_0_0}`,
                translate: true,
            },
            {
                key: 'release-0-9-1',
                label: 'general.release091',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_1}`,
                translate: true,
            },
            {
                key: 'release-0-9-0',
                label: 'general.release090',
                to: `${ROUTES.RELEASE_NOTES}/${ROUTES.RELEASE_0_9_0}`,
                translate: true,
            },
        ],
    },
]

const HelpAndInfo = () => {
    const t = useTranslate()
    const { isSmallerThanXlScreen } = useWindowDimensions()
    const { pathname } = useLocation()
    const showDefaultContent = pathname.endsWith(ROUTES.HELP_AND_INFO)

    return (
        <div className="m-boxes -left -settings">
            <div className="m-boxes__breadcrumbs">
                <Breadcrumbs
                    breadcrumbs={[
                        {
                            label: 'general.infoCenter',
                            to: pathname,
                            icon: ICONS.HOMEPAGE_BREADCRUMB,
                            showAlways: true,
                        },
                    ]}
                />
            </div>
            <div
                className={`m-boxes__main -withDefaultContent fullHeightBoxes ${
                    isSmallerThanXlScreen ? '-mt20' : ''
                }`}
            >
                {showDefaultContent && (
                    <div className="-defaultContent column aligned-center justify-center fullHeight">
                        <Icon name={ICONS.HELP_AND_INFO_DESCRIPTION} />
                        <span className="a-bodyTextMedium -mb20 -mt20">
                            {t('general.helpAndInfoTitle')}
                        </span>
                        <p className="a-mediumText centered-text a-lightText -defaultContentDescription">
                            {t('general.helpAndInfoDescription')}
                        </p>
                    </div>
                )}
                <Outlet />
            </div>
            <div className="m-boxes__side column fullHeightBoxes">
                <span className="a-bodyTextMedium -boxTitle -mb10">
                    {t('general.helpAndInfo')}
                </span>
                <TabNavigation tabs={TABS} columnView />
            </div>
        </div>
    )
}

export default HelpAndInfo
