import React from 'react'

import { ACCESS_CONTROL_MODULES } from 'constants/enums'

import DataAccessControl from './components/dataAccessControl/DataAccessControl'

const PastEmploymentAccessControl = () => {
    return (
        <DataAccessControl
            module={ACCESS_CONTROL_MODULES.PAST_EMPLOYMENT}
            accessTypeLabel="form.label.selectAccessForEmploymentHistory"
            dataAccessAttribute="accessPastEmployment"
            title="general.employmentHistory"
        />
    )
}

export default PastEmploymentAccessControl
