import ENTITIES from 'constants/entities'
import { ALERT_TYPES } from 'constants/enums'
import { AlertContext } from 'contexts/AlertContext'
import { useContext } from 'react'
import { useTranslate } from 'react-polyglot'
import { deleteEntityService } from 'services/entity.service'
import { changeUserArray } from 'utils/changeUserArray'

const useRemoveFromList = () => {
    const t = useTranslate()
    const { setAlert } = useContext(AlertContext)

    const removeUser = async (item, users, setUsers, fetchData, divers) => {
        try {
            await deleteEntityService(ENTITIES.FAVORITE_USER, item.favoriteId ?? item.id)

            setAlert(t('message.userRemoveFromFavorites'), ALERT_TYPES.SUCCESS)
            changeUserArray(item, users, setUsers, divers)
            await fetchData()
        } catch (error) {
            setAlert(error, ALERT_TYPES.ERROR)
        }
    }
    return removeUser
}

export { useRemoveFromList }
