const FILTERS = {
    DIVERS: 'snd_diver_filters',
    DIVE_PROJECTS: 'snd_dive_project_filters',
    HISTORICAL_DIVE_PROJECTS: 'snd_historical_dive_project_filters',
    DIVE_TEAM: 'snd_project_dive_team',
    CONTRACTOR_HOMEPAGE: 'snd_contractor_homepage',
    COMPANIES: 'company_filters',
    JOBS: 'job_filters',
    DIVE_RECORDS: 'snd_dive_record_filters',
}

export default FILTERS
