import ENTITIES from 'constants/entities'

import { createEntityService } from 'services/entity.service'

import { PastEmploymentForm } from './..'

const CreatePastEmployment = ({ open, setOpen, fetchData }) => {
    const handleSubmit = async (formData) => {
        await createEntityService(ENTITIES.PAST_EMPLOYMENT, formData, true)
        fetchData()
    }

    return (
        <PastEmploymentForm
            title="general.addNewEmploymentHistory"
            open={open}
            setOpen={setOpen}
            handleSubmit={handleSubmit}
        />
    )
}

export default CreatePastEmployment
