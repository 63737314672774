import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useTranslate } from 'react-polyglot'

import { isFormDisabled } from 'utils/form'

import FieldError from 'components/FieldError'
import FieldLabel from 'components/FieldLabel'

const TextArea = ({
    label,
    placeholder,
    required,
    error,
    disabled,
    showLabel,
    showPlaceholder,
    largeTextarea,
    xxlTextarea,
    flexibleTextarea,
    ...props
}) => {
    const t = useTranslate()

    const fieldRef = useRef()

    const isDisabled = disabled || isFormDisabled(fieldRef)

    return (
        <div
            scroll-attribute={props.name}
            className={`m-textarea ${error ? '-error' : ''} ${
                isDisabled ? '-disabled' : ''
            } ${largeTextarea ? '-largeTextarea' : ''} ${
                xxlTextarea ? '-xxlTextarea' : ''
            } ${flexibleTextarea ? '-flexibleTextarea' : ''} `}
        >
            {showLabel && (
                <FieldLabel
                    htmlFor={props.name}
                    label={label || `form.label.${props.name}`}
                    required={required}
                />
            )}
            <textarea
                {...props}
                ref={fieldRef}
                placeholder={
                    showPlaceholder
                        ? t(placeholder || `form.placeholder.${props.name}`)
                        : ''
                }
                disabled={isDisabled}
            />
            {error && <FieldError className="errorMessage" error={error} />}
        </div>
    )
}

export const TextAreaMainPropTypes = {
    name: PropTypes.string.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool,
    showLabel: PropTypes.bool,
    showPlaceholder: PropTypes.bool,
    largeTextarea: PropTypes.bool,
    xxlTextarea: PropTypes.bool,
    flexibleTextarea: PropTypes.bool,
}

TextArea.propTypes = {
    ...TextAreaMainPropTypes,
    value: PropTypes.any,
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

TextArea.defaultProps = {
    required: false,
    disabled: false,
    showLabel: true,
    showPlaceholder: true,
    largeTextarea: false,
    xxlTextarea: false,
    flexibleTextarea: false,
}

export default TextArea
