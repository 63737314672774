import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

import CardImage from './card/CardImage'

const CardContainer = ({
    children,
    noBorderCard,
    sidebarCard,
    link,
    cardImage,
    handleClick,
}) => {
    return (
        <div
            className={`a-stepCard ${noBorderCard ? '-noBorderCard' : ''} ${
                cardImage ? '-withCardImage' : ''
            } ${link || handleClick ? '-withHover' : ''} ${
                sidebarCard ? '-sidebarCard' : ''
            } ${!children ? '-withOutBody' : ''}`}
        >
            {cardImage && <CardImage cardImage={cardImage} />}
            {children && <div className="a-stepCard__body">{children}</div>}
            {(link || handleClick) && (
                <Link
                    to={link}
                    onClick={handleClick}
                    className="-cardContainerLink"
                ></Link>
            )}
        </div>
    )
}

CardContainer.propTypes = {
    noBorderCard: PropTypes.bool,
    sidebarCard: PropTypes.bool,
    link: PropTypes.string,
    cardImage: PropTypes.object,
    handleClick: PropTypes.func,
}

CardContainer.defaultProps = {
    noBorderCard: false,
    sidebarCard: false,
}

export default CardContainer
